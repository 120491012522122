@import "./colors.scss";

.main-wrapper {
	width: 100%;
	// height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;

	.top-nav-container {
		height: 45px;
		width: 100%;
		background-color: var(--primary-color);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0px 20px;

		.toggle-icon {
			display: flex;
			color: #fff;
			cursor: pointer;

			img {
				width: 30px;
				height: 30px;
			}
			.company-title {
				margin-left: 20px;
			}
		}

		.user-settings {
			display: flex;
			flex-direction: column;
			position: absolute;
			right: 0;
			margin: auto;
			top: 10px;

			&:hover .user-dropdown {
				display: block;
			}

			.user-icon-nav {
				padding-right: 20px;
				color: beige;
				text-align: right;
				cursor: pointer;
			}

			.user-dropdown {
				display: none;
				margin-top: 9px;
				min-width: 200px;
				opacity: 1;
				cursor: pointer;
				background: linear-gradient(
						90deg,
						var(--primary-color) 0%,
						var(--secondary-color) 100%
					)
					0% 0% no-repeat padding-box;
				box-shadow: 2px 2px 4px rgba(28, 18, 34, 0.2);
				border-radius: 0px 0px 0px 5px;
				z-index: 1;

				&:hover {
					display: block;
				}

				ul {
					padding: 25px 0px 0px 10px;
					margin: 0;

					li {
						height: 35px;
						width: 100px;
						text-align: center;
						line-height: 35px;
						list-style-type: none;

						a {
							text-decoration: none;
						}
						.menu-container {
							color: #fff;
						}

						&:nth-child(n + 1) {
							margin-bottom: 8px;
						}

						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.main-container {
		// background-color: gold;
		width: 100%;
		display: flex;
		flex-direction: row;

		.side-nav-content {
			display: none;
			background-color: var(--primary-color);
			width: 250px;
			min-height: 300px;
			transition: 2s;
			// width: 0px;
			// margin-top: 10px;
			// position: relative;
			// transition-delay: 1s;
		}

		.main-content {
			width: 100%;
			min-height: 90vh;
			margin-top: 10px;
			margin-left: 20px;
			padding: 20px 10px;

			.filter-wrapper {
				min-height: 50px;
				width: 100%;
				position: relative;
				margin-bottom: 50px;
				border: 0.5px solid gray;
				border-radius: 5px;

				.filter-container {
					width: 100%;
					min-height: 50px;
					margin-bottom: 10px;
					display: flex;
					padding: 25px 20px 10px 20px;
					flex-wrap: wrap;
					gap: 20px;
					background: linear-gradient(
							90deg,
							var(--primary-color) 0%,
							var(--secondary-color) 100%
						)
						0% 0% no-repeat padding-box;

					& .search {
						background-color: white;
					}
				}

				.filter-button-container {
					height: 45px;
					width: 100%;
					position: absolute;
					bottom: 0;
					margin: auto;
					padding: 0px 20px;
					gap: 20px;
					display: flex;
					justify-content: flex-end;
					background: linear-gradient(
							90deg,
							var(--primary-color) 0%,
							var(--secondary-color) 100%
						)
						0% 0% no-repeat padding-box;

					button {
						height: 30px;
						width: 100px;
						background: var(--venue-9);
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1180px) {
	.main-wrapper {
		position: relative;

		.top-nav-wrapper {
			.toggle-icon {
				visibility: visible;
				// display: none;
			}
		}

		.main-container {
			// background-color: salmon;
			width: 100%;

			.side-nav-wrapper {
				// visibility: hidden;
				display: none;
			}

			.content-wrapper {
				width: 100%;
				min-height: 90vh;
				background-color: hotpink;
				margin-top: 10px;
				margin-left: 0;
			}
		}
	}
}
